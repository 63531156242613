@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: local('Material Icons'), local('MaterialIcons-Regular'), url(https://fonts.gstatic.com/s/materialicons/v22/2fcrYFNaTjcS6g4U3t-Y5ewrjPiaoEww8AihgqWRJAo.woff) format('woff');
}

.flex {
  display: flex;
}

.btn-primary {
  color: #0077a3;
}

.btn-primary:hover {
  color: #198cce;
  background-color: rgba(25, 140, 206, .2) !important;
}

.btn-remove {
  color: #868686;
  opacity: .5;
}

.btn--add-semester {
  width: 100%;
  background-color: #e663c3 !important;
  color: #fff !important;
  height: 40px !important;
  border-radius: 2px;
  line-height: 40px !important;
  text-transform: none !important;
  font-size: 17px !important;

  span {
    font-size: 100% !important;
  }
}

.btn-remove:hover {
  opacity: 1;
  color: #868686;
}

.btn-remove:not(.btn-icon):hover {
  background-color: rgba(134, 134, 134, .2);
}

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  font-feature-settings: 'liga';
}

.GpaCalculator-container {
  max-width: 768px;
  margin: 0 auto;
  padding: 25px;
}

.RadioGroup-switch .Course-item {
  width: auto;
  margin-right: 1.5rem;
}

.RadioGroup-switch input[type="radio"] + div {
  border-color: #828282;
}

.RadioGroup-switch input[type="radio"]:checked + div {
  border-color: #E663C3;
}

.RadioGroup-switch input[type="radio"]:checked ~ span {
  color: #222;
}

.RadioGroup-switch input[type="radio"]:checked + div:before {
  background-color: #E663C3;
}

.center {
  text-align: center;
}

@media (max-width: 420px) {
  .GpaCalculator-container {
    padding: 15px;
  }

  .RadioGroup-switch {
    flex-wrap: wrap;
  }

  .RadioGroup-switch .Course-item {
    width: 100%;
    text-align: left;
  }

  .btn-primary {
    font-size: 14px;
  }
}
